import './App.css';
import { useState } from 'react';
import {Switch,Route} from 'react-router-dom';
import Home from './Pages/Home';
import Profile from './Pages/profile';
import Chat from './Pages/chat';
import History from './Pages/jobhistory';
import JobAssigned from './Pages/jobassigned';
import CurrentJobs from './Pages/currentjobs';
import Earnings from './Pages/earning';
import Details from './Pages/details';
import Details1 from './Pages/details1';
import Login from './Pages/login';
import Contact from './Pages/contact';
import ResetPassword from './Pages/ResetPassword';
import ForgotPassword from './Pages/ForgotPassword'
import AllChats from './Pages/allchats';

function App() {
  return (
    <div className="App">
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/profile" component={Profile}/>
          <Route exact path="/chat" component={Chat}/>
          <Route exact path="/job-history" component={History}/>
          <Route exact path="/job-assigned" component={JobAssigned}/>
          <Route exact path="/current-jobs" component={CurrentJobs}/>
          <Route exact path="/earnings" component={Earnings}/>
          <Route exact path="/job-details" component={Details}/>
          <Route exact path="/job-details1" component={Details1}/>
          <Route exact path="/all-chats" component={AllChats}/>

        <Route exact path="/resetpassword" component={ForgotPassword}/>
        <Route exact path="/reset-password/:token" component={ResetPassword}/>
        </Switch>
        <Contact/>
    </div>
  );
}

export default App;
