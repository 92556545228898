import React, { useEffect, useState } from "react";
import Navbar from "../Components/navbar";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBBtn,
  MDBIcon,

} from "mdb-react-ui-kit";
import Lognavbar from "../Components/lognavbar";
import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

import axios from "axios";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Footer from "./footer";

export default function JobAssigned() {
  const [basicActive, setBasicActive] = useState("tab1");
  const [basicModal, setBasicModal] = useState(false);
  const [jobsAssigned, setjobs] = useState([]);
  const [selectedjob, setselectedjob] = useState();
  const [completedjobs, setcompletedjobs] = useState();
  const [rejectedjobs, setrejectedjobs] = useState();

  const toggleOpen = (job) => {
    setBasicModal(!basicModal);
    setselectedjob(job);

    console.log(selectedjob);
  };

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
  }, []);

  const fetchAssignedJobs = async (viewersId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/main/assignedjobsviewers`,
        { viewersId }
      );

      console.log("aaaaaa",response.data.data);
      setjobs(response.data.data);
    } catch (err) {
      console.error("Error fetching assigned jobs:", err);
    }
  };

  const fetchcompletedjobs = async (viewersId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/main/jobhistory`,
        { viewersId }
      );

      console.log("Jobs data", response.data.data);
      setcompletedjobs(response.data.data);
    } catch (err) {
      console.error("Error fetching assigned jobs:", err);
    }
  };

  const fetchrejectedjobs = async (viewersId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/main/rejectviewerjobs`,
        { viewersId }
      );

      console.log("Rejected Data", response.data.data);
      setrejectedjobs(response.data.data);
    } catch (err) {
      console.error("Error fetching assigned jobs:", err);
    }
  };
  const [viewersId, setviewersId] = useState();

  useEffect(() => {
    const viewersIds = localStorage.getItem("ID");
    setviewersId(viewersIds);
    fetchAssignedJobs(viewersIds);
    fetchcompletedjobs(viewersIds);
    fetchrejectedjobs(viewersIds);
  }, []);

  const handleAccept = async (job_id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/main/acceptjob`,
        {
          job_id: job_id.Id,
          seekersId: job_id.seekersId,
          packagename: job_id.packagename,
          viewername: `${job_id.firstname} ${job_id.lastname}`,
          emailviewer: job_id.email
        }
      );

      console.log(response.data.data);
    } catch (err) {
      console.error("Error fetching assigned jobs:", err);
    }
    setBasicModal(false);
    fetchAssignedJobs(viewersId);
    fetchcompletedjobs(viewersId);
    fetchrejectedjobs(viewersId);
  };

  const handlereject = async (job_id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/main/rejectjob`,
        { job_id }
      );

      console.log(response.data.data);
    } catch (err) {
      console.error("Error fetching assigned jobs:", err);
    }
    setBasicModal(false);
    fetchAssignedJobs(viewersId);
    fetchcompletedjobs(viewersId);
    fetchrejectedjobs(viewersId);
  };
  const [basicModal2, setBasicModal2] = useState(false);
  const [userdata, setuserdata] = useState();

  const fetchviewerdetails = async () => {
    setBasicModal2(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/seekers/profile/get`,
        {
          params: {
            Id: selectedjob.seekersId,
          },
        }
      );

      console.log(response.data.data[0]);

      setuserdata(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  function convertToUKTime(dateString) {
    const date = new Date(dateString);
  
    const options = {
      timeZone: 'Europe/London',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
  
    const ukTimeString = date.toLocaleString('en-GB', options);
  
    return ukTimeString;
  }

  return (
    <div className="about">
      <Lognavbar />
      <div className="head">
        <h1
          className="mainheading"
          style={{ color: "#ff4e00", marginTop: "2%" }}
        >
          Assigned Jobs
        </h1>
        <MDBTabs className="mb-3">
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab1")}
              active={basicActive === "tab1"}
            >
              Assigned
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab3")}
              active={basicActive === "tab3"}
            >
              Rejected
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        <center>
          {basicActive === "tab1" ? (
            <MDBRow
              className="row-cols-1 row-cols-md-4 g-4"
              style={{ margin: "30px" }}
            >
              {jobsAssigned &&
                jobsAssigned.map((assigned) => (
                  <MDBCol key="">
                    <MDBCard
                      className="h-100"
                      style={{ backgroundColor: "#1a2229" }}
                    >
                       <MDBCardImage
                        style={{ height: "160px" }}
                        src={
                          assigned.packagename === "Basic Viewing"
                            ? "Assets/2.png"
                            : assigned.packagename === "Pro Viewing"
                            ? "Assets/4.png"
                            : "Assets/property.png"
                        }
                        position="top"
                        alt="..."
                      />

                      

                      <MDBCardBody>
                        <MDBCardTitle
                          style={{ marginTop: "25px", color: "white" }}
                        >
                          {assigned.packagename}
                        </MDBCardTitle>
                        <p style={{color:'white'}}><span style={{color:"#ff4e00"}}>Ref No: </span> {assigned.Id+1000}</p>

                        <MDBCardText>
                          <MDBBtn
                            style={{
                              color: "white",
                              backgroundColor: "#ff4e00",
                            }}
                            onClick={() => {
                              toggleOpen(assigned);
                            }}
                          >
                            Details
                          </MDBBtn>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))}
            </MDBRow>
          ) : (
            <MDBRow
              className="row-cols-1 row-cols-md-4 g-4"
              style={{ margin: "30px" }}
            >
              {rejectedjobs &&
                rejectedjobs.map((assigned) => (
                  <MDBCol key="">
                    <MDBCard
                      className="h-100"
                      style={{ backgroundColor: "#1a2229" }}
                    >
                     
                     <MDBCardImage
                        style={{ height: "160px" }}
                        src={
                          assigned.packagename === "Basic Viewing"
                            ? "Assets/2.png"
                            : assigned.packagename === "Pro Viewing"
                            ? "Assets/4.png"
                            : "Assets/property.png"
                        }
                        position="top"
                        alt="..."
                      />


                      <MDBCardBody>
                        <MDBCardTitle
                          style={{ marginTop: "25px", color: "white" }}
                        >
                          {assigned.packagename}
                        </MDBCardTitle>

                        <p style={{color:'white'}}><span style={{color:"#ff4e00"}}>Ref No: </span> {assigned.Id+1000}</p>

                        <MDBCardText>
                          <MDBBtn
                            style={{
                              color: "white",
                              backgroundColor: "#ff4e00",
                            }}
                            onClick={() => {
                              toggleOpen(assigned);
                            }}
                          >
                            Details
                          </MDBBtn>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))}
            </MDBRow>
          )}
        </center>

        <div
        className="decmargin"
          style={{ textAlign: "left", marginLeft: "60px", marginTop: "20px" }}
        >
          <h4 style={{ color: "white" }}>Job History</h4>
          <MDBTable responsive style={{ width: "95%" }}>
            <MDBTableHead
              style={{ backgroundColor: "#1a2229", color: "#ff4e00" }}
            >
              <tr>
                <th scope="col">Job Name</th>
                <th scope="col">Viewer Name</th>
                <th scope="col">Rating</th>
                <th scope="col">Dated</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody style={{ color: "white" }}>
              {completedjobs &&
                completedjobs.map((completedjob) => {
                  return (
                    <>
                      <tr>
                        <td>{completedjob.packagename}</td>
                        <td>
                          {completedjob.firstname}
                          {completedjob.lastname}
                        </td>
                        <td>
                          {completedjob.jobratings > 0
                            ? Array.from({
                                length: completedjob.jobratings,
                              }).map((_, index) => (
                                <MDBIcon
                                  key={index}
                                  style={{ color: "#ff4e00" }}
                                  icon="star"
                                />
                              ))
                            : "N/A"}
                        </td>{" "}
                        <td>{convertToUKTime(completedjob.completedAt)}</td>
                      </tr>
                    </>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </div>
      </div>

      <MDBModal
        open={basicModal}
        onClose={() => setBasicModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#ff4e00" }}>
                Job Details
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md={12}>
                  <MDBCardTitle style={{ marginTop: "25px", color: "white" }}>
                    {selectedjob && selectedjob.packagename}
                  </MDBCardTitle>


                  <MDBBtn
                    style={{
                      color: "white",
                      backgroundColor: "#ff4e00",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      fetchviewerdetails();
                      toggleOpen();
                    }}
                  >
                    Check Seekers Details
                  </MDBBtn>
                  <p
                    style={{
                      color: "#ff4e00",
                      fontWeight: "bold",
                      fontSize: "19px",
                    }}
                  >
                    $
                    {selectedjob && selectedjob.paid
                     }
                  </p>


                  {selectedjob &&
                    selectedjob.packagename === "Basic Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          The property can be found here,{" "}
                          <strong>
                            {selectedjob.Q1URL
                              ? <a href={selectedjob.Q1URL} target="_blank" rel="noopener noreferrer">
                                  {selectedjob.Q1URL}
                                </a>
                              : selectedjob.street1
                              ? `${selectedjob.street1}, ${selectedjob.city}, ${selectedjob.country}`
                              : "N/A"}
                          </strong>
                          . They{" "}
                          <strong>
                            {selectedjob.Q3
                              ? selectedjob.Q3 === "yes"
                                ? "have"
                                : "have not"
                              : "N/A"}
                          </strong>{" "}
                          communicated with agent/property management. We can
                          reach them on{" "}
                          <strong>
                            {selectedjob.q3part1 ? selectedjob.q3part1 : "N/A"}
                          </strong>
                          , and what they said was{" "}
                          <strong>
                            {selectedjob.q3part2 ? selectedjob.q3part2 : "N/A"}
                          </strong>
                          . They are{" "}
                          <strong>
                            {selectedjob.Q4
                              ? selectedjob.Q4 === "yes"
                                ? "okay"
                                : "not okay"
                              : "N/A"}
                          </strong>{" "}
                          with 10 working days to get viewing done.{" "}
                          {selectedjob.Q4 === "no" ? (
                            <span>
                              They want it done in{" "}
                              <strong>{selectedjob.Q4Date}</strong>.
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <p>
                          They want to be communicated via{" "}
                          <strong>
                            {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedjob.Q5 === "email" ? (
                            <strong> on {selectedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                          . The promo code used is{" "}
                          <strong>
                            {selectedjob.promo ? selectedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}

                  {selectedjob && selectedjob.packagename === "Pro Viewing" && (
                    <div className="job-details text-white">
                      <p>
                        The property can be found here,{" "}
                        <strong>
                          {selectedjob.Q1URL
                            ? <a href={selectedjob.Q1URL} target="_blank" rel="noopener noreferrer">
                              {selectedjob.Q1URL}
                            </a>
                            : selectedjob.street1
                            ? `${selectedjob.street1}, ${selectedjob.city}, ${selectedjob.country}`
                            : "N/A"}
                        </strong>{" "}
                        They{" "}
                        <strong>
                          {selectedjob.Q2
                            ? selectedjob.Q2 === "yes"
                              ? "have specific concerns about the property"
                              : "have not specific concerns about the property"
                            : "N/A"}
                        </strong>{" "}
                        They are concerned about
                        <strong>
                          {selectedjob.Q2 === "yes"
                            ? `  ${selectedjob.Q2specify}`
                            : ""}
                        </strong>{" "}
                        They
                        <strong>
                          {selectedjob.Q3
                            ? selectedjob.Q3 === "yes"
                              ? "  have"
                              : "  have not"
                            : "N/A"}
                        </strong>{" "}
                        communicated with agent/property management. We can
                        reach them on{" "}
                        <strong>
                          {selectedjob.q3part1 ? selectedjob.q3part1 : "N/A"}
                        </strong>
                        , and what they said was{" "}
                        <strong>
                          {selectedjob.q3part2 ? selectedjob.q3part2 : "N/A"}
                        </strong>
                        . They are{" "}
                        <strong>
                          {selectedjob.Q4
                            ? selectedjob.Q4 === "yes"
                              ? "okay"
                              : "not okay"
                            : "N/A"}
                        </strong>{" "}
                        with 10 working days to get viewing done.{" "}
                        {selectedjob.Q4 === "no" ? (
                          <span>
                            They want it done in{" "}
                            <strong>{selectedjob.Q4Date}</strong>.
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <p>
                        They want to be communicated via{" "}
                        <strong>
                          {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                        </strong>{" "}
                        {selectedjob.Q5 === "email" ? (
                          <strong>on {selectedjob.Q5text}</strong>
                        ) : (
                          ""
                        )}
                        . The promo code used is{" "}
                        <strong>
                          {selectedjob.promo ? selectedjob.promo : "none"}
                        </strong>
                        .
                      </p>
                    </div>
                  )}

                  {selectedjob &&
                    selectedjob.packagename !== "Basic Viewing" &&
                    selectedjob.packagename !== "Pro Viewing" && (
                      <div className="job-details text-white">
                        <p>
                          They plan on living at{" "}
                          <strong>
                            {selectedjob.Q1 ? selectedjob.Q1 : "N/A"}
                          </strong>
                          . They plan on{" "}
                          <strong>
                            {selectedjob.Q6 ? selectedjob.Q6 : "N/A"}
                          </strong>
                          .
                        </p>

                        {selectedjob.Q6 === "Renting" && (
                          <p>
                            If it's renting; Their allocated budget for this
                            property is{" "}
                            <strong>
                              {selectedjob.Q2 ? selectedjob.Q2 : "N/A"}
                            </strong>
                            . They are looking to rent for{" "}
                            <strong>
                              {selectedjob.Q3 ? selectedjob.Q3 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        {selectedjob.Q6 === "Buying" && (
                          <p>
                            They want a property within the price range of{" "}
                            <strong>
                              {" "}
                              £{selectedjob.Q7 ? selectedjob.Q7 : "N/A"}
                            </strong>
                            .
                          </p>
                        )}

                        <p>
                          They are looking for a property with these
                          preferences:{" "}
                          <strong>
                            {selectedjob.Q4 ? selectedjob.Q4 : "N/A"}
                          </strong>
                          . They want to be communicated via{" "}
                          <strong>
                            {selectedjob.Q5 ? selectedjob.Q5 : "N/A"}
                          </strong>{" "}
                          {selectedjob.Q5 === "Email" ? (
                            <strong>on {selectedjob.Q5text}</strong>
                          ) : (
                            ""
                          )}
                        </p>

                        <p>
                          The promo code used is{" "}
                          <strong>
                            {selectedjob.promo ? selectedjob.promo : "none"}
                          </strong>
                          .
                        </p>
                      </div>
                    )}


                 
                </MDBCol>
              </MDBRow>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                onClick={() => {
                  handleAccept(selectedjob);
                }}
                style={{ color: "white", backgroundColor: "green" }}
              >
                Accept
              </MDBBtn>
              <MDBBtn
                style={{
                  color: "white",
                  backgroundColor: "red",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  handlereject(selectedjob.Id);
                }}
              >
                Reject
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>


      <MDBModal
        open={basicModal2}
        onClose={() => setBasicModal2(false)}
        tabIndex="-1"
      >
        <MDBModalDialog centered>
          <MDBModalContent style={{ backgroundColor: "black" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ color: "#ff4e00" }}>
                Seeker Details
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={()=>{
                  setBasicModal2(false)
                }}
              ></MDBBtn>
            </MDBModalHeader>

            <MDBModalBody>
              {/* <img
                src={`${process.env.REACT_APP_BASE_URL}/files/${
                  userdata && userdata.profilePicture
                }`}
                alt=""
                style={{ width: "100px", height: "100px", borderRadius: "50%" }}
              /> */}
              <MDBRow style={{ marginTop: "20px" }}>
                <MDBCol md={6}>
                  <div>
                    <label htmlFor="" style={{ color: "#ff4e00" }}>
                      Seeker Name
                    </label>
                    <p style={{ color: "white" }}>
                      {userdata && userdata.firstName+' '+userdata.lastName}
                    </p>
                  </div>

                  <div>
                    <label htmlFor="" style={{ color: "#ff4e00" }}>
                      Email
                    </label>
                    <p style={{ color: "white" }}>
                      {userdata && userdata.email}
                    </p>
                  </div>
                 
                </MDBCol>
                <MDBCol md={6}>
                  <div>
                    <label htmlFor="" style={{ color: "#ff4e00" }}>
                      Gender
                    </label>
                    <p style={{ color: "white" }}>
                      {userdata && userdata.gender}
                    </p>
                  </div>
                  {/* <div>
                    <label htmlFor="" style={{ color: "#ff4e00" }}>
                      Phone No
                    </label>
                    <p style={{ color: "white" }}>
                      {userdata && userdata.mobile}
                    </p>
                  </div> */}
                 
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <Footer />
    </div>
  );
}
