import React, { useEffect, useState } from "react";
import Lognavbar from "../Components/lognavbar";
import { MDBBtn } from "mdb-react-ui-kit";
import Cookies from "js-cookie";
import axios from "axios";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useLocation } from "react-router-dom";
import Auth from './auth'
import { Link } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AllChats = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [ws, setWs] = useState(null);
  const sentMessages = new Map();
  const audioRef = React.useRef();
  const [seekersmessages, setallseekersmessages] = useState("");
  const query = useQuery();
  const seekersId = query.get("seekersId");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [name,setName]=useState('');
  const [profilePic,setProfilePic]=useState('');

  function generateMessageId() {
    return Math.random().toString(36).substr(2, 9);
  }

  useEffect(() => {
    const viewersId = localStorage.getItem("ID");
  
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    const newWs = new WebSocket("wss://socket.asareviewing.com");
    setWs(newWs);
  
    const GetProfileAndData = async () => {
      await GetProfile();
      if (viewersId) {
        await GetReviews(viewersId, seekersId);
      }
      await GetChatAllSeekers();
    };
  
    GetProfileAndData();
  
    window.addEventListener("resize", handleResize);
  
    document.body.style.backgroundColor = "#000000";
  
    newWs.onopen = () => {
      console.log("WebSocket connected");
    };
  
    newWs.onmessage = (messageEvent) => {
      const receivedMessage = JSON.parse(messageEvent.data);
      setMessages((prevMessages) => [...prevMessages, receivedMessage]);
      const messageId = receivedMessage.id;
  
      if (sentMessages.has(messageId)) {
        const message = sentMessages.get(messageId);
        message.status = "read";
        sentMessages.set(messageId, message);
      }
    };
  
    newWs.onclose = () => {
      console.log("WebSocket disconnected");
    };
  
    return () => {
      window.removeEventListener("resize", handleResize);
      newWs.close();
    };
  }, [seekersId]);
  

  async function GetProfile() {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/seekers/profile/get?Id=${seekersId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data.data;
      setProfilePic(responseData[0].profilePicture);
      setName(responseData[0].firstName);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const GetReviews = async (viewersId, seekersId) => {
    try {

      console.log("seekersId",seekersId,"viewersId",viewersId)
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/GetChat`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            viewersId,
            seekersId,
          },
        }
      );
      const responseData = response.data.data;

      console.log("responseData",responseData);
      setMessages(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  async function GetChatAllSeekers() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/GetChat/GetChatAllSeekers`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            viewersId: Cookies.get("B_Id"),
          },
        }
      );
      const responseData = response.data.data;

      setallseekersmessages(response.data.data);

      console.log(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <section>

      <Auth/>
      <Lognavbar />
      <audio ref={audioRef} src="../Assets/notify.wav" />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
            <h5
            className="font-weight-bold mb-3 text-center text-lg-start"
            style={{ color: "white" }}
            >
            Contacts
            </h5>
            <div className="card" style={{ backgroundColor: "#1a2229" }}>
                <div className="card-body">
                <ul className="list-unstyled mb-0">
                  {seekersmessages &&
                    seekersmessages.map((seeker, index) => {
                      return (
                        <li
                          className="p-2 border-bottom"
                          style={{ backgroundColor: "#000000" }}
                        >
                          <a
                            key={seeker.seekersId}
                            href={`/chat?seekersId=${seeker.seekersId}&jobId=${seeker.job_id}`}
                            className="d-flex justify-content-between"
                          >
                            <div className="d-flex flex-row">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}/files/${seeker.profilePicture}`}
                                alt="avatar"
                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                width="60"
                                height="60"
                              />
                              <div
                                className="pt-1"
                                style={{ textAlign: "left" }}
                              >
                                <p
                                  className="fw-bold mb-0"
                                  style={{ color: "#ff4e00" }}
                                >
                                  {seeker.firstName}
                                  {seeker.lastName} <span style={{color:"white",fontSize:"12px",marginLeft:"7px"}}>(Ref No: {seeker.job_id+1000})</span>
                                </p>
                                <p className="small text-muted">
                                  {seeker.message}
                                </p>
                              </div>
                            </div>
                          </a>
                        </li>
                      );
                    })}
                </ul>
                </div> 
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllChats;
