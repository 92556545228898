import React, { useState } from 'react';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBTextArea,
    MDBRow,
    MDBInput
} from 'mdb-react-ui-kit';

export default function Contact() {
    const [basicModal, setBasicModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneno: '',
        message: ''
    });

    const toggleOpen = () => setBasicModal(!basicModal);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/asare/contact/contactviewer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                alert('Email sent successfully!');
                setFormData({
                    name: '',
                    email: '',
                    phoneno: '',
                    message: ''
                });
                toggleOpen();
            } else {
                alert('Failed to send email.');
            }
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email.');
        }
    };

    return (
        <div>

            <div
                style={{
                    width: "60px",
                    height: "60px",
                    backgroundColor: "#ff4e00",
                    borderRadius: "50%",
                    alignContent: "center",
                    position: "fixed",
                    bottom: "10px",
                    right: "30px",
                    cursor: "pointer"
                }}
                onClick={toggleOpen}
            >
                <i className='fa fa-envelope' style={{ fontSize: "30px", color: "white" }}></i>
            </div>

            <MDBModal  open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog centered>
                    <MDBModalContent style={{ backgroundColor: "black" }}>
                        <MDBModalHeader>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={handleSubmit}>
                            <MDBModalBody>
                                <MDBRow style={{ margin: "20px" }}>

                                <MDBModalTitle style={{ color: "#ff4e00" }}>Send Us Mail</MDBModalTitle>

                                    <div className="mb-4">
                                        <label htmlFor="name" style={{ textAlign: 'left', display: 'block', color: "white" }}>Name</label>
                                        <MDBInput
                                            id="name"
                                            wrapperClass='mb-4'
                                            required
                                            name='name'
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="email" style={{ textAlign: 'left', display: 'block', color: "white" }}>Email</label>
                                        <MDBInput
                                            id="email"
                                            wrapperClass='mb-4'
                                            required
                                            name='email'
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="phoneno" style={{ textAlign: 'left', display: 'block', color: "white" }}>Phone Number</label>
                                        <MDBInput
                                            id="phoneno"
                                            wrapperClass='mb-4'
                                            required
                                            name='phoneno'
                                            value={formData.phoneno}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="message" style={{ textAlign: 'left', display: 'block', color: "white" }}>Message</label>
                                        <MDBTextArea
                                            id="message"
                                            wrapperClass='mb-4'
                                            rows={4}
                                            required
                                            name='message'
                                            value={formData.message}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </MDBRow>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn type="submit" style={{ color: "white", backgroundColor: "#ff4e00", marginLeft: "10px" }}>Send</MDBBtn>
                            </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}
