import React, { useEffect, useState } from "react";
import Navbar from "../Components/navbar";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Cookies from "js-cookie";
import { MDBBtn, MDBContainer, MDBInput, MDBSpinner } from "mdb-react-ui-kit";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Lognavbar from "../Components/lognavbar";

export default function Login() {
  useEffect(() => {
    document.body.style.fontFamily = "bahnschrift";
    document.body.style.backgroundColor = "#000000";
  }, []);

  const initialForm = {
    email: "",
    password: "",
  };

  const [formData, setFormData] = useState(initialForm);
  const [submit, setSubmit] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  async function handleLogin(e) {
    e.preventDefault();

    try {
      setSubmit(true);

      console.log(formData);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/login`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data;
      console.log(responseData);
      if (responseData.message == "success") {
        setFormData(initialForm);
        Cookies.set("V_email", responseData.email, { expires: 1 });
        Cookies.set("V_token", responseData.token, { expires: 1 });
        Cookies.set("V_Id", responseData.Id, { expires: 1 });
        Cookies.set("V_Picture", responseData.image, { expires: 1 });
        Cookies.set("B_Id", responseData.Id, { expires: 1 });

        localStorage.setItem("ID", responseData.Id);
        window.location.href = "/home";
      } else if (responseData.message == "failure") {
        errorNotify();
      }
      setSubmit(false);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const SuccessNotify = () => {
    toast.success("Login Successful", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const errorNotify = () => {
    toast.error("Invalid Login");
  };


  const reset=()=>
  {
    window.location.href="/resetpassword";
  }




  return (
    <div>
      {/* <Lognavbar/> */}
      <ToastContainer />
      <MDBContainer
        fluid
        className="d-flex align-items-center justify-content-center bg-image"
        style={{ minHeight: "100vh" }}
      >
        <div className="mask gradient-custom-3"></div>
        <div className="m-5" style={{ width: "400px" }}>
          <a href="https://asareviewing.com">
            <img
              src="Assets/logo.png"
              alt=""
              className="img-fluid logomain1"
              srcset=""
            />
          </a>

          <h3 style={{ color: "white", marginTop: "5%" }}>LOGIN</h3>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label
                htmlFor="form1"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Email
              </label>
              <MDBInput
                size="lg"
                id="form1"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="form1"
                style={{ textAlign: "left", display: "block", color: "white" }}
              >
                Password
              </label>
              <MDBInput
                size="lg"
                id="form1"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>
            <MDBBtn
              className="mb-4 w-100 gradient-custom-4"
              size="lg"
              style={{
                borderRadius: "0",
                color: "black",
                backgroundColor: "white",
              }}
            >
              {submit ? <MDBSpinner></MDBSpinner> : <span>Submit</span>}
            </MDBBtn>
          </form>

          <MDBBtn onClick={reset}
          className="mb-4"
          size="sm"

          color="white"
          style={{outline:"0", borderRadius: "0" ,border:0, background: "transparent",color:"#FF4E00" }}
        >
          Forgot Password ?
        </MDBBtn>
        </div>

     
      </MDBContainer>
    </div>
  );
}
