import React, { useEffect, useState,useRef } from "react";
import Lognavbar from "../Components/lognavbar";
import { MDBBtn } from "mdb-react-ui-kit";
import Cookies from "js-cookie";
import axios from "axios";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useLocation } from "react-router-dom";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Auth from './auth'
import { Link } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [ws, setWs] = useState(null);
  const sentMessages = new Map();
  const audioRef = React.useRef();
  const [seekersmessages, setallseekersmessages] = useState("");
  const query = useQuery();
  const seekersId = query.get("seekersId");
  const jobId = query.get("jobId");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [name, setName] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const endOfMessagesRef = useRef(null);

  function generateMessageId() {
    return Math.random().toString(36).substr(2, 9);
  }

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    const viewersId = localStorage.getItem("ID");

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    connectWebSocket(); // Initial WebSocket connection

    const GetProfileAndData = async () => {
      await GetProfile();
      if (viewersId) {
        await GetReviews(viewersId, seekersId, jobId);
      }
      await GetChatAllSeekers();
    };

    GetProfileAndData();

    window.addEventListener("resize", handleResize);

    document.body.style.backgroundColor = "#000000";

    return () => {
      window.removeEventListener("resize", handleResize);
      if (ws) {
        ws.close();
      }
    };
  }, [seekersId]);

  const connectWebSocket = () => {
    const newWs = new W3CWebSocket("wss://vast-gamy-green.glitch.me");
    setWs(newWs);

    newWs.onopen = () => {
      console.log("WebSocket connected");
    };

    newWs.onmessage = (messageEvent) => {
      const receivedMessage = JSON.parse(messageEvent.data);
      setMessages((prevMessages) => {
        if (receivedMessage.job_id === jobId) {
            return [...prevMessages, receivedMessage];
        }
        return prevMessages;
      });
      const messageId = receivedMessage.id;

      if (sentMessages.has(messageId)) {
        const message = sentMessages.get(messageId);
        message.status = "read";
        sentMessages.set(messageId, message);
      }
    };

    newWs.onclose = () => {
      console.log("WebSocket disconnected, reconnecting...");
      setTimeout(connectWebSocket, 1000); 
    };
  };

  async function GetProfile() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/seekers/profile/get?Id=${seekersId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data.data;
      setProfilePic(responseData[0].profilePicture);
      setName(responseData[0].firstName);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const GetReviews = async (viewersId, seekersId, jobId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/GetChat`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            viewersId,
            seekersId,
            jobId
          },
        }
      );
      const responseData = response.data.data;

      console.log("responseData", responseData);
      setMessages(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  async function GetChatAllSeekers() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/asare/GetChat/GetChatAllSeekers`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            viewersId: Cookies.get("B_Id"),
          },
        }
      );
      const responseData = response.data.data;
      console.log(responseData);

      setallseekersmessages(response.data.data);

      console.log(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const sendMessage = () => {
    if (ws && ws.readyState === 1 && input!="") {
      const messageId = generateMessageId();
      const userImage =
        Cookies.get("V_Picture") &&
        Cookies.get("V_Picture").startsWith("https://")
          ? Cookies.get("V_Picture")
          : `${process.env.REACT_APP_BASE_URL}/files/${Cookies.get("V_Picture")}`;
  
      const fileInput = document.getElementById("fileInput");
      const file = fileInput.files[0];
  
      const uploadFileToCloudinary = (file) => {
        document.getElementById("filetext").style.display = "block";
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "wukncq9d");
  
        return axios.post('https://api.cloudinary.com/v1_1/dge3lt4u6/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log('File uploaded successfully:', response.data);
          return response.data.secure_url; 
        })
        .catch(error => {
          console.error('Error uploading file:', error);
          throw error;
        });
      };
  
      const sendMessageToWebSocket = (fileUrl = null) => {

        let messageObject = {
          id: messageId,
          userId: Cookies.get("B_Id"),
          viewersId: Cookies.get("B_Id"),
          seekersId: seekersId,
          viewerEmail: Cookies.get("V_email"),
          userImage: userImage,
          jobId:jobId,
          recieverName: "Viewer",
          chatId: "1",
          text:input,
          file: fileUrl ? { url: fileUrl } : null,
        };
  
        const messageString = JSON.stringify(messageObject);
        ws.send(messageString);
  
        sentMessages.set(messageId, {
          text: input,
          status: "not read",
          file: fileUrl ? { url: fileUrl } : null,
        });
  
        setInput("");
        document.getElementById("filetext").style.display = "none";
        document.getElementById("fileInput").value = null;
      };
  
      if (file) {
        uploadFileToCloudinary(file)
          .then((fileUrl) => {
            sendMessageToWebSocket(fileUrl);
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      } else {
        sendMessageToWebSocket();
      }
    } else {
      console.log("WebSocket not connected");
    }
  };  

  function convertToUKTime(dateString) {
    const date = new Date(dateString);
  
    const options = {
      timeZone: 'Europe/London',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
  
    const ukTimeString = date.toLocaleString('en-GB', options);
  
    return ukTimeString;
  }

  return (
    <section>
      <Auth />
      <Lognavbar />
      <audio ref={audioRef} src="../Assets/notify.wav" />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
            {!isMobile ? (
              <h5
                className="font-weight-bold mb-3 text-center text-lg-start"
                style={{ color: "white" }}
              >
                Contacts
              </h5>
            ) : (
              ""
            )}
            <div
              className="card"
              style={{ backgroundColor: "#1a2229" }}
            >
              {!isMobile ? (
                <div className="card-body">
                  <ul className="list-unstyled mb-0">
                    {seekersmessages &&
                      seekersmessages.map((seeker, index) => {
                        return (
                          <li
                            className="p-2 border-bottom"
                            style={{ backgroundColor: "#000000" }}
                          >
                            <a
                              key={seeker.seekersId}
                              href={`/chat?seekersId=${seeker.seekersId}&jobId=${seeker.job_id}`}
                              className="d-flex justify-content-between"
                            >
                              <div className="d-flex flex-row">
                                <img
                                  src={`${process.env.REACT_APP_BASE_URL}/files/${seeker.profilePicture}`}
                                  alt="avatar"
                                  className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                  width="60"
                                  height="60"
                                />
                                <div
                                  className="pt-1"
                                  style={{ textAlign: "left" }}
                                >
                                  <p
                                    className="fw-bold mb-0"
                                    style={{ color: "#ff4e00" }}
                                  >
                                    {seeker.firstName}
                                    {seeker.lastName} <span style={{color:"white",fontSize:"12px",marginLeft:"7px"}}>(Ref No: {seeker.job_id+1000})</span>
                                  </p>
                                  <p className="small text-muted">
                                    {seeker.message}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ) : (
                <div style={{ textAlign: "left", padding: "10px" }}>
                  <Link
                    style={{ color: "white", marginLeft: "10px" }}
                    to="/all-chats"
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{ color: "#ff4e00", marginRight: "10px" }}
                    ></i>
                    Back to Contacts
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-7 col-xl-8">
            <ul class="list-unstyled">
              <li
                class="d-flex justify-content-between mb-4"
                style={{
                  backgroundColor: "#1a2229",
                  height: "400px",
                  overflowY: "auto",
                }}
              >
                <div
                  class="card"
                  style={{ backgroundColor: "#1a2229", width: "100%" }}
                >
                  <div class="card-header d-flex p-3">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/files/${profilePic}`}
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    />
                    <div style={{ marginLeft: "12px" }}>
                      <p class="fw-bold mb-0" style={{ color: "#ff4e00" }}>
                        {name} <span style={{color:"white",fontSize:"12px",marginLeft:"7px"}}>(Ref No: {parseInt(jobId)+1000})</span>
                      </p>
                    </div>
                  </div>
                  <div class="card-body message-container">
                    {messages &&
                      messages.map((item, index) => (
                        <MDBRow>
                          <MDBCol md={7}>
                          {item.recieverName !== "Viewer" ? (
                              <div
                                class="mb-0"
                                style={{
                                  textAlign: "left",
                                  justifyContent: "flex-start",
                                  display: "flex",
                                }}
                              >
                                <img
                                  src={item.userImage}
                                  alt=""
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                  <p style={{ color: "#ff4e00" }}>
                                    {item.firstName && item.lastName
                                      ? item.firstName + " " + item.lastName
                                      : "Seeker"}
                                      <span style={{color:"white",fontSize:"10px",marginLeft:"10px"}}>{convertToUKTime(item.timestamp)}</span>
                                  </p>
                                  <p
                                    style={{
                                      color: "white",
                                      marginTop: "-17px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item.text}
                                  </p>
                                  {item.file ? (
                                    <div
                                      style={{
                                        width: "200px",
                                        height: "50px",
                                        borderRadius: "5px",
                                        padding: "10px",
                                        border: "1px solid white",
                                        marginTop: "-8px",
                                        display: "flex",
                                      }}
                                    >
                                      <a
                                        href={`${item.file}`}
                                        target="_blank"
                                      >
                                        <i
                                          className="fa fa-file"
                                          style={{
                                            marginTop: "4px",
                                            color: "#ff4e00",
                                          }}
                                        ></i>{" "}
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            marginTop: "4px",
                                            color: "white",
                                            fontSize: "12px",
                                          }}
                                        >
                                          File
                                        </span>
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </MDBCol>
                          <MDBCol md={5}>
                          {item.recieverName === "Viewer" ? (
                              <div
                                class="mb-0"
                                style={{
                                  textAlign: "left",
                                  justifyContent: "flex-start",
                                  display: "flex",
                                }}
                              >
                                <img
                                  src={item.userImage}
                                  alt=""
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                  <p style={{ color: "#ff4e00" }}>
                                    {item.viewersId == Cookies.get("B_Id")
                                      ? "ME"
                                      : item.recieverName}
                                      <span style={{color:"white",fontSize:"10px",marginLeft:"10px"}}>{convertToUKTime(item.timestamp)}</span>
                                  </p>
                                  <p
                                    style={{
                                      color: "white",
                                      marginTop: "-17px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item.text}
                                  </p>
                                  {item.file ? (
                                    <div
                                      style={{
                                        width: "200px",
                                        height: "50px",
                                        borderRadius: "5px",
                                        padding: "10px",
                                        border: "1px solid white",
                                        marginTop: "-8px",
                                        display: "flex",
                                      }}
                                    >
                                      <a
                                        target="_blank"
                                        href={`${item.file}`}
                                      >
                                        <i
                                          className="fa fa-file"
                                          style={{
                                            marginTop: "4px",
                                            color: "#ff4e00",
                                          }}
                                        ></i>{" "}
                                        <span
                                          style={{
                                            marginLeft: "7px",
                                            marginTop: "4px",
                                            color: "white",
                                            fontSize: "12px",
                                          }}
                                        >
                                          File
                                        </span>
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </MDBCol>
                        </MDBRow>
                      ))}
                  </div>
                </div>
              </li>
              <div style={{ marginTop: "-17px" }}>
                <li>
                  <div data-mdb-input-init class="form-outline">
                    <textarea
                      class="form-control"
                      id="textAreaExample2"
                      rows="4"
                      required
                      style={{
                        backgroundColor: "#1a2229",
                        color: "white",
                        border: "1px solid white",
                      }}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          sendMessage();
                        }
                      }}
                    ></textarea>

                    <label class="form-label" for="textAreaExample2">
                      Message
                    </label>
                  </div>
                  <div
                    class="form-outline"
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                  >
                    <input
                      class="form-control"
                      id="fileInput"
                      type="file"
                      style={{
                        backgroundColor: "#1a2229",
                        color: "white",
                        border: "1px solid white",
                      }}
                    />
                  </div>
                </li>
                <p style={{color:"white",display:"none"}} id="filetext">File is Uploading....</p>
                <p className="my-2" style={{ color: "white" }}>
                      This Chat Is Being Monitored Please Treat Every Client
                      With Respect
                    </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <MDBBtn
                    style={{
                      color: "white",
                      visibility: "hidden",
                      backgroundColor: "#ff4e00",
                    }}
                  >
                    Job Completed
                  </MDBBtn>
                  <MDBBtn
                    style={{
                      color: "white",
                      backgroundColor: "#ff4e00",
                      marginBottom:"30px"
                    }}
                    onClick={sendMessage}
                  >
                    <i
                      className="fa fa-send"
                      style={{ marginRight: "10px"}}
                    ></i>
                    Send
                  </MDBBtn>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chat;

