import React, { useState } from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBBtn
} from "mdb-react-ui-kit";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

export default function Lognavbar() {
  const [openBasic, setOpenBasic] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(()=>{
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  },[])

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const logout=()=>{
    Cookies.remove('V_email');
    Cookies.remove('V_token');
    Cookies.remove('V_Id');
    Cookies.remove('B_Id');

    
    window.location.href="/";
  }

  return (
    <MDBNavbar expand='lg' style={{position:"sticky",top:"0",backgroundColor:"black",zIndex:"9999"}}>
    <MDBContainer fluid>
    <MDBNavbarBrand href='#'>
        <Link to="/home">
          <div style={{ textAlign: 'center',alignContent:"center",alignItems:"center" }}>
          <img src="Assets/logo.png" alt="" className="img-fluid logomain" srcset="" />

          </div>
        </Link>
      </MDBNavbarBrand>
  
      <MDBNavbarToggler
        aria-controls='navbarSupportedContent'
        aria-expanded='false'
        aria-label='Toggle navigation'
        onClick={() => setOpenBasic(!openBasic)}
      >
        <MDBIcon icon='bars' fas style={{ color: "white" }}/>
      </MDBNavbarToggler>
  
      <MDBCollapse navbar open={openBasic} style={{color:"white"}}>
        <MDBNavbarNav className={isMobile ? "justify-content-start align-items-start" : "justify-content-end"} style={{ marginTop: "20px" }}>
        <MDBNavbarItem>
            <MDBNavbarLink active aria-current='page' href='#' style={{ marginRight: "10px",marginTop:"5px" }}>
              <Link to="/earnings" className="links">Earning</Link>
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink active aria-current='page' href='#' style={{ marginRight: "10px",marginTop:"5px" }}>
              <Link to="/job-assigned" className="links">Assigned Jobs</Link>
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink active aria-current='page' href='#' style={{ marginRight: "10px",marginTop:"5px" }}>
              <Link to="/current-jobs" className="links">Current Jobs</Link>
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink active aria-current='page' href='#' style={{ marginRight: "10px",marginTop:"5px" }}>
              <Link to="/chat" className="links">Chat</Link>
            </MDBNavbarLink>
          </MDBNavbarItem>          
          <MDBNavbarItem style={{ marginRight: "10px",marginTop:"5px" }}>
            <MDBNavbarLink active aria-current='page' href=''>
              <Link to="/profile" className="links">Profile</Link>
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBDropdown show={isOpen} onClick={toggleDropdown} style={{ color: "white" }}>
            <MDBDropdownToggle
              tag="a"
              className="nav-link d-flex align-items-center"
              href="#"
              style={{ color: "white" }}
            >
              <img
                src={Cookies.get('V_Picture') && Cookies.get('V_Picture').startsWith('https://')?Cookies.get('V_Picture'):`${process.env.REACT_APP_BASE_URL}/files/${Cookies.get('V_Picture')}`}
                className="rounded-circle"
                height="30"
                width="30"
                alt="Avatar"
                loading="lazy"
              />
            </MDBDropdownToggle>
            <MDBDropdownMenu style={{padding:"10px"}}>
              <MDBDropdownItem>
                <Link onClick={logout} to="/">Logout</Link>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBContainer>
  </MDBNavbar>  
  );
}