import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

const Auth = () => {
  const history = useHistory();

  useEffect(() => {
    const token = Cookies.get('V_token');
    if (!token) {
      history.push('/');
    }
  }, [history]);

  return null;
};

export default Auth;
