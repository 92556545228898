import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBInput,
  MDBTextArea,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Lognavbar from '../Components/lognavbar';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import axios from 'axios';

export default function Profile() {
  const [profile, setProfile] = useState([]);
  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => setBasicModal(!basicModal);

  useEffect(() => {
    document.body.style.backgroundColor = '#000000';
    GetProfile();
  }, []);

  const initialForm = {
    profilePicture: null,
    email: '',
    about: '',
    password: '',
    facebook: '',
    linkedin: '',
    instagram: '',
  };

  const [formData, setFormData] = useState(initialForm);
  const [submit, setSubmit] = useState(false);
  const [submitImage, setSubmitImage] = useState(false);

  async function GetProfile() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/profile/get?Id=${Cookies.get('V_Id')}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = response.data.data || [];

      if (responseData.length > 0) {
        const firstProfile = responseData[0];
        setFormData({
          profilePicture: firstProfile.profilePicture,
          email: firstProfile.email || '',
          about: firstProfile.about || '',
          password: '',
          facebook: firstProfile.facebook || '',
          linkedin: firstProfile.linkedin || '',
          instagram: firstProfile.instagram || '',
        });
        Cookies.set('V_Picture', firstProfile.profilePicture);
        setProfile(responseData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function handleImage(e) {
    try {
      setSubmitImage(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/profile/update?Id=${Cookies.get('V_Id')}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const responseData = response.data;
      if (responseData.message === 'success') {
        toggleOpen();
        setSubmitImage(false);
        GetProfile();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function handleProfile(e) {
    try {
      setSubmit(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/viewers/profile/update?Id=${Cookies.get('V_Id')}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = response.data;
      if (responseData.message === 'success') {
        NotifyNew();
        setSubmit(false);
        GetProfile();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'profilePicture') {
      const file = files[0];

      setFormData((prevFormData) => ({
        ...prevFormData,
        profilePicture: file,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const NotifyNew = () => {
    toast.success('Profile Update', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <div>
      <Lognavbar />
      <ToastContainer />
      <MDBContainer fluid className='d-flex align-items-center justify-content-center bg-image' style={{ minHeight: '100vh' }}>
        <div className='mask gradient-custom-3'></div>
        <div className='m-5' style={{ width: '400px' }}>
          <h3 style={{ color: 'white', marginTop: '5%' }}>Profile Settings</h3>
          <center>
            <div style={{ display: 'flex' }}>
              <img
                src={Cookies.get('V_Picture') && Cookies.get('V_Picture').startsWith('https://') ? Cookies.get('V_Picture') : `${process.env.REACT_APP_BASE_URL}/files/${Cookies.get('V_Picture')}`}
                alt=''
                style={{ width: '100px', borderRadius: '50%', height: '100px' }}
              />
              <div style={{ textAlign: 'left' }}>
                <MDBBtn style={{ color: 'white', backgroundColor: '#ff4e00', height: '38px', marginTop: '25px', marginLeft: '20px' }} onClick={toggleOpen}>
                  Change Image
                </MDBBtn>
                <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                  <BootstrapSwitchButton checked={true} onstyle='warning' offstyle='light' style='border' />
                </div>
              </div>
            </div>
          </center>
          <div className='mb-4'>
            <label htmlFor='form1' style={{ textAlign: 'left', display: 'block', color: 'white' }}>
              Email
            </label>
            <MDBInput size='lg' type='email' name='email' value={formData.email} onChange={handleInputChange} />
          </div>

          <div className='mb-4'>
            <label htmlFor='form1' style={{ textAlign: 'left', display: 'block', color: 'white' }}>
              Password
            </label>
            <MDBInput size='lg' type='password' name='password' value={formData.password} onChange={handleInputChange} />
          </div>

          <div className='mb-4'>
            <label htmlFor='form1' style={{ textAlign: 'left', display: 'block', color: 'white' }}>
              About
            </label>
            <MDBTextArea wrapperClass='mb-4' rows={4} required name='about' value={formData.about} onChange={handleInputChange} />
          </div>

          <div className='mb-4'>
            <label htmlFor='form1' style={{ textAlign: 'left', display: 'block', color: 'white' }}>
              Facebook Profile
            </label>
            <MDBInput size='lg' type='text' name='facebook' value={formData.facebook} onChange={handleInputChange} />
          </div>

          <div className='mb-4'>
            <label htmlFor='form1' style={{ textAlign: 'left', display: 'block', color: 'white' }}>
              LinkedIn Profile
            </label>
            <MDBInput size='lg' type='text' name='linkedin' value={formData.linkedin} onChange={handleInputChange} />
            </div>

            <div className='mb-4'>
        <label htmlFor='form1' style={{ textAlign: 'left', display: 'block', color: 'white' }}>
          Instagram Profile
        </label>
        <MDBInput size='lg' type='text' name='instagram' value={formData.instagram} onChange={handleInputChange} />
      </div>

      <MDBBtn className='mb-4 w-100 gradient-custom-4' size='lg' style={{ borderRadius: '0', color: 'black', backgroundColor: 'white' }} onClick={handleProfile}>
        {submit ? <MDBSpinner /> : <span>Update Profile</span>}
      </MDBBtn>
    </div>
  </MDBContainer>

  <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
    <MDBModalDialog centered>
      <MDBModalContent style={{ backgroundColor: '#1a2229' }}>
        <MDBModalHeader>
          <MDBModalTitle style={{ color: '#ff4e00' }}>Change Photo</MDBModalTitle>
          <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
          <div className='mb-4' style={{ marginTop: '20px' }}>
            <label htmlFor='form1' style={{ textAlign: 'left', display: 'block', color: 'white' }}>
              Profile Picture
            </label>
            <MDBInput size='lg' type='file' name='profilePicture' onChange={handleInputChange} />
          </div>
        </MDBModalBody>

        <MDBModalFooter>
          <MDBBtn style={{ color: 'white', backgroundColor: '#ff4e00' }} onClick={handleImage}>
            {submitImage ? <MDBSpinner /> : <span>Update</span>}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModalContent>
    </MDBModalDialog>
  </MDBModal>
</div>
);
}